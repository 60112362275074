import { api } from '../../api';

/**
 * @param {number} videoId
 * @param {VideoModerationProcessingStatus} status
 * @returns {Promise<Video>}
 */
export async function changeModerationStatus(videoId, status) {
  // eslint-disable-next-line no-useless-catch
  try {
    const response = await api.put(`/v1/video/${videoId}/changeModerationStatus`, {
      moderationProcessingStatus: status,
    });
    return response.data.video;
  } catch (error) {
    throw error;
  }
}

/**
 * @param {number} videoId
 * @returns {Promise<Video>}
 */
export async function startQVBR(videoId) {
  // eslint-disable-next-line no-useless-catch
  try {
    const response = await api.put(`/v1/video/${videoId}/startQVBR`, {});
    return response.data.video;
  } catch (error) {
    throw error;
  }
}
