import cn from 'classnames';
import React, { useState } from 'react';
import {
  Button, Col, Row,
} from 'react-bootstrap';
import { FaPlay, FaCheckCircle } from 'react-icons/fa';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { MdOutlineRestartAlt } from 'react-icons/md';
import imgDelete from '../../../../../assets/images/delete.svg';
import {
  VideoModerationProcessingStatus,
  VideoProcessingStatus,
} from '../../../../../dorian-shared/types/media/MediaConfigs';
import { getVideoProcessingStatus } from '../../../../utils/media';
import { isAdminRole } from '../../../../utils/userActionUtils';
import { VideoModal } from '../../VideoModal/VideoModal';
import { changeModerationStatus, startQVBR } from '../../videosAPI';
import { DeleteVideoModal } from '../DeleteVideo/DeleteVideoModal';
import { listColumnsSize } from '../Videos/constants';
import classNames from './Video.module.scss';

export function Video({
  video,
  onChange,
}) {
  const [isDeleteModalShown, setIsDeleteModalShown] = useState(false);
  const [isVideoModalShown, setIsVideoModalShown] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const { canBePicked, statusText, isFailed } = getVideoProcessingStatus(video);
  const isAdmin = isAdminRole();

  const handleVideoDelete = () => {
    setIsDeleteModalShown(false);
    onChange();
  };

  const handleVideoCancel = () => setIsDeleteModalShown(false);

  const handleDeleteClick = () => setIsDeleteModalShown(true);

  const handleVideoPreviewClick = () => setIsVideoModalShown(true);

  const handleVideoModalClose = () => setIsVideoModalShown(false);

  const handleApproveModeration = async () => {
    setIsProcessing(true);
    await changeModerationStatus(video.id, VideoModerationProcessingStatus.Completed);
    setIsProcessing(false);
    onChange();
  };

  const handleDispproveModeration = async () => {
    setIsProcessing(true);
    await changeModerationStatus(video.id, VideoModerationProcessingStatus.Failed);
    setIsProcessing(false);
    onChange();
  };

  const handleStartQVBR = async () => {
    setIsProcessing(true);
    await startQVBR(video.id);
    setIsProcessing(false);
    onChange();
  };

  const canBeReprocessed = ![VideoProcessingStatus.Processing, VideoProcessingStatus.Completed].includes(video.processingStatus);
  const canBeModerationApproved = VideoModerationProcessingStatus.Completed !== video.moderationProcessingStatus;
  const canBeModerationDisapproved = VideoModerationProcessingStatus.Completed === video.moderationProcessingStatus;
  const triggeredCategories = video?.moderationDetails?.triggeredCategories;

  return (
    <>
      <Row className={cn(classNames.item, { [classNames.isLoading]: isProcessing })} title={video.title}>
        <Col sm={listColumnsSize.title} className={cn({ [classNames.grayedout]: !canBePicked })}>
          <Col className={classNames.fieldTitle}>
            {video.title}
          </Col>
          {statusText && (
            <Col className={isFailed ? classNames.fieldProcessingStatusError : classNames.fieldProcessingStatus}>
              {statusText}
            </Col>
          )}
          <Col className={classNames.fieldAlias}>
            {video.alias}
          </Col>
          {isAdmin && triggeredCategories && (
            <Col className={classNames.moderationTriggeredCategories}>
              <div className={classNames.moderationTriggeredCategoriesTitle}>Triggered categories</div>
              <div className={classNames.moderationTriggeredCategoriesList}>
                {triggeredCategories.map((category) => (
                  <div key={category}>{category}</div>
                ))}
              </div>
            </Col>
          )}
        </Col>
        <Col sm={listColumnsSize.actions} className={classNames.fieldActions}>
          {isAdmin && (
            <>
              {canBeModerationApproved && (
                <Button
                  variant="outline-primary"
                  size="sm"
                  onClick={handleApproveModeration}
                  title="Approve moderation"
                  className={cn('mr-2 buttonPlay', classNames.button)}
                >
                  <FaCheckCircle />
                  mod
                </Button>
              )}
              {canBeModerationDisapproved && (
                <Button
                  variant="outline-primary"
                  size="sm"
                  onClick={handleDispproveModeration}
                  title="Disapprove moderation"
                  className={cn('mr-2 buttonPlay', classNames.button)}
                >
                  <IoIosCloseCircleOutline />
                  mod
                </Button>
              )}
              {canBeReprocessed && (
                <Button
                  variant="outline-primary"
                  size="sm"
                  onClick={handleStartQVBR}
                  title="Start QVBR"
                  className={cn('mr-2 buttonPlay', classNames.button)}
                >
                  <MdOutlineRestartAlt />
                  QVBR
                </Button>
              )}
            </>
          )}
          <Button
            variant="outline-primary"
            size="sm"
            onClick={handleVideoPreviewClick}
            className="mr-2 buttonPlay"
          >
            <FaPlay />
          </Button>
          <Button
            onClick={handleDeleteClick}
            variant="secondary"
            size="sm"
            className="mx-1"
            disabled={!canBePicked}
          >
            <img src={imgDelete} className="btnImg" alt="Delete" />
          </Button>
        </Col>
      </Row>
      { isDeleteModalShown && (
        <DeleteVideoModal
          video={video}
          onDelete={handleVideoDelete}
          onCancel={handleVideoCancel}
        />
      )}
      <VideoModal
        video={video}
        isVisible={isVideoModalShown}
        onClose={handleVideoModalClose}
      />
    </>
  );
}
