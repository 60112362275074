import cn from 'classnames';
import React, { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { FaPlay } from 'react-icons/fa';
import { VideoModal } from 'components/pages/VideoLibraryPage/VideoModal/VideoModal';
import { getVideoProcessingStatus } from '../../../../utils/media';
import { listColumnsSize } from '../VideosBasicWithPick/constants';
import classNames from './VideoBasicWithPick.module.scss';

export function VideoBasicWithPick({
  video,
  isSelected,
  onClick,
  onDbClick,
}) {
  const { canBePicked, statusText, isFailed } = getVideoProcessingStatus(video);
  const handleClick = () => {
    if (!canBePicked) {
      return;
    }
    onClick(video);
  };
  const handleDoubleClick = () => {
    if (!canBePicked) {
      return;
    }
    onDbClick(video);
  };
  const [isVideoModalShown, setIsVideoModalShown] = useState(false);

  return (
    <>
      <Row
        className={cn(classNames.item, { [classNames.selected]: isSelected, [classNames.grayedout]: !canBePicked })}
        onClick={handleClick}
        onDoubleClick={handleDoubleClick}
        title={statusText}
      >
        <Col sm={listColumnsSize.title}>
          <Col className={classNames.fieldTitle}>
            {video.title}
          </Col>
          {statusText && (
            <Col className={isFailed ? classNames.fieldProcessingStatusError : classNames.fieldProcessingStatus}>
              {statusText}
            </Col>
          )}
          <Col className={classNames.fieldAlias}>
            {video.alias}
          </Col>
        </Col>
        <Col sm={listColumnsSize.actions} className={classNames.fieldActions}>
          <Button
            variant="outline-primary"
            size="sm"
            onClick={() => setIsVideoModalShown(true)}
            className="mr-2 buttonPlay"
          >
            <FaPlay />
          </Button>
        </Col>
      </Row>
      <VideoModal
        video={video}
        isVisible={isVideoModalShown}
        onClose={() => setIsVideoModalShown(false)}
      />
    </>
  );
}
