import { CurrencyPreference } from '../../dorian-shared/types/CurrencyPreference/CurrencyPreference';

export interface PriceOption {
  value: number;
  label: string;
}

interface PriceRange {
  start: number;
  step: number;
  steps: number;
}

const PRICE_RANGES: readonly PriceRange[] = [
  { start: 0, step: 1, steps: 20 }, // 1..20 inclusive
  { start: 20, step: 5, steps: 6 }, // 20..40 inclusive with step of 5
  { start: 50, step: 10, steps: 15 }, // 50..200 with step of 10
  { start: 200, step: 100, steps: 8 }, // 300..1000 with step of 100
] as const;

/**
 * Function generates a complete list of possible prices according to the specified logic.
 * The first "price" in the list is 0 (special '--' option in the selector).
 */
function buildPriceValues(): number[] {
  const prices: number[] = [];

  // Add "zero" option
  prices.push(0);

  // Iterate through each range
  PRICE_RANGES.forEach(({ start, step, steps }) => {
    for (let i = 1; i <= steps; i++) {
      prices.push(start + step * i);
    }
  });

  return prices;
}

/**
 * Check if we need to show "♥" based on currency settings
 */
function shouldShowHeartIcon(currency: CurrencyPreference | undefined): boolean {
  // If currency is not provided or it's in the list below - display heart
  if (!currency) return true;
  return [
    CurrencyPreference.SapphiresFirst,
    CurrencyPreference.HeartsFirst,
    CurrencyPreference.HeartsOnly,
  ].includes(currency);
}

/**
 * Generates an array of price options (value/label) for the dropdown list.
 * The icon (heart) is displayed depending on the currency toggle settings.
 *
 * @param currencyToggleSettings - User's currency preference settings
 * @returns Array of objects { value, label }
 */
export function generatePriceOptions(
  currencyToggleSettings: CurrencyPreference = CurrencyPreference.HeartsFirst,
): PriceOption[] {
  const allPrices = buildPriceValues();
  const icon = shouldShowHeartIcon(currencyToggleSettings) ? '♥' : '';

  return allPrices.map((value) => {
    // For the very first element (value=0) output "--" without an icon
    const label = value === 0 ? '--' : `${value} ${icon}`.trim();
    return { value, label };
  });
}
